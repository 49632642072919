<template>
  <div>
    <div class="lg:flex lg:space-x-10">
      <div class="w-full lg:px-20 space-y-7">
        <div class="card p-3">
          <ul class="divide-y" v-if="comments.data && comments.data.length">
            <latest-comment-item :length="200" :comment="comment" v-for="(comment, index) in comments.data" :key="'comment' + index"/>
          </ul>

          <div class="mt-4">
            <v-pagination
                class="justify-content-center"
                v-if="comments.paginatorInfo.lastPage > 1"
                v-model="comments.paginatorInfo.currentPage"
                :pages="comments.paginatorInfo.lastPage"
                :range-size="1"
                active-color="#DCEDFF"
                @update:modelValue="loadComments"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "../../core/services/api.service";
import LatestCommentItem from "./LatestCommentItem";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import {changePageTitle} from "../../core/services/utils.service";

export default {
  name: "CommentList",
  components: {LatestCommentItem, VPagination},
  data() {
    return {
      comments: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0
        }
      }
    }
  },
  methods: {
    loadComments() {
      let query = `query($page: Int) {
        latestComments(first:10, orderBy: [{column: "created_at", order:DESC}], page: $page) {
          data {
            id
            content
            created_at
            user {
              id
              username
              avatar {
                url
              }
            }
            object {
              __typename
              ... on Song {
                id
                title
                slug
              }
              ... on Folk {
                id
                title
                slug
              }
              ... on Instrumental {
                id
                title
                slug
              }
              ... on Karaoke {
                id
                title
                slug
              }
              ... on Poem {
                id
                title
                slug
              }
              ... on Composer {
                id
                title
                slug
              }
              ... on Artist {
                id
                title
                slug
              }
              ... on Poet {
                id
                title
                slug
              }
              ... on Recomposer {
                id
                title
                slug
              }
              ... on Document {
                id
                title
                slug
              }
              ... on Discussion {
                id
                title
                slug
              }
              ... on Playlist {
                id
                title
                slug
              }
              ... on Sheet {
                id
                title
                slug
              }
              ... on Page {
                id
                title
                slug
              }
              ... on VovProgram {
                id
                title
                slug
              }
              ... on Role {
                id
                name
                slug
              }
              ... on Upload {
                id
              }
            }
          },
          paginatorInfo {
            currentPage
            lastPage
            total
          }
        }
      }`;

      ApiService.graphql(query, {page: this.comments.paginatorInfo.currentPage})
          .then(({data}) => {
            if (data.data && data.data.latestComments) {
              this.comments = data.data.latestComments;
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
  },
  mounted() {
    this.loadComments();
    changePageTitle("Bình luận");
  }
}
</script>
